/* import { useState } from "react"; */
import { Controller, useFormContext } from "react-hook-form";
import { Select } from "@repo/ui/select";
import { Field } from "./field";

function ClassSelect({
  options,
}: {
  options: { value: string; label: string }[];
}): JSX.Element {
  const { setValue } = useFormContext();
  /*   const [isOpen, setIsOpen] = useState(false); */

  return (
    <Controller
      name="class"
      render={({ field }) => {
        return (
          <Field
            component={
              <Select
                /* onOpenChange={(open) => {
                  setIsOpen(open);
                }} */
                onValueChange={(value) => {
                  setValue(field.name, value, { shouldValidate: true });
                }}
                value={field.value as string}
              >
                <Select.Trigger className="py-0">
                  <Select.Value className="px-4" placeholder="Select" />
                </Select.Trigger>
                <Select.Content>
                  <Select.Group>
                    {options.map((option) => (
                      <Select.Item
                        className="font-diablo"
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </Select.Item>
                    ))}
                  </Select.Group>
                </Select.Content>
              </Select>
            }
            label="Class"
          />
        );
      }}
    />
  );
}

export { ClassSelect };
