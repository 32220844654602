import {
  useFormContext,
  useFieldArray,
  type UseFieldArrayAppend,
  type UseFieldArrayRemove,
  type FieldArrayWithId,
} from "react-hook-form";
import { X } from "lucide-react";
import { type Values } from "../../../schema";
import { AddAffixCombobox } from "./add-affix-combobox";
import { Affix } from "./affix";

interface AffixesApi {
  appendAffix: UseFieldArrayAppend<Values, `statFilters.${number}.affixes`>;
  removeAffix: UseFieldArrayRemove;
  affixes: FieldArrayWithId<Values, `statFilters.${number}.affixes`>[];
}

export interface Option {
  value: string;
  label: string;
}

export function StatFilter({
  children,
  removeStat,
  statIndex,
  affixOptions,
  statType,
}: {
  statIndex: number;
  removeStat: AffixesApi["removeAffix"];
  children: React.FunctionComponent<AffixesApi>;
  affixOptions: Option[];
  statType: string;
}): JSX.Element {
  const { control } = useFormContext<Values>();
  const {
    fields: affixes,
    append: appendAffix,
    remove: removeAffix,
  } = useFieldArray({
    control,
    name: `statFilters.${statIndex}.affixes`,
  });

  return (
    <div className="border border-gray-700">
      <Header removeStat={removeStat} statIndex={statIndex} type={statType} />
      <Content>
        {children({
          affixes,
          appendAffix,
          removeAffix,
        })}
        <AddAffixCombobox
          appendAffix={appendAffix}
          options={affixOptions}
          statIndex={statIndex}
        />
      </Content>
    </div>
  );
}

StatFilter.Affix = Affix;

function Header({
  removeStat,
  statIndex,
  type,
}: {
  removeStat: AffixesApi["removeAffix"];
  statIndex: number;
  type: string;
}): JSX.Element {
  return (
    <div className="flex h-8 items-center justify-between px-2">
      <Title type={type} />
      <RemoveStatFilterBtn
        onClick={() => {
          removeStat(statIndex);
        }}
      />
    </div>
  );
}

function Content({ children }: React.PropsWithChildren): JSX.Element {
  return <div className="grid items-center">{children}</div>;
}

function Title({ type }: { type: string }): JSX.Element {
  return (
    <h4>
      Stat Filter <span className="uppercase">({type})</span>
    </h4>
  );
}

function RemoveStatFilterBtn(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>,
): JSX.Element {
  return (
    <button {...props} type="button">
      <X className="h-4 w-4" strokeWidth={4} />
    </button>
  );
}
