import { Controller, useFormContext } from "react-hook-form";
import { Select } from "@repo/ui/select";
import { Field } from "./field";

function SocketsSelect({
  options,
}: {
  options: { value: string; label: string }[];
}): JSX.Element {
  const { setValue } = useFormContext();

  return (
    <Controller
      name="sockets"
      render={({ field }) => {
        return (
          <Field
            component={
              <Select
                onValueChange={(value) => {
                  setValue(field.name, value, { shouldValidate: true });
                }}
                value={field.value as string}
              >
                <Select.Trigger className="py-0">
                  <Select.Value className="px-4" placeholder="Select" />
                </Select.Trigger>
                <Select.Content>
                  <Select.Group>
                    {options.map((option, i) => {
                      return (
                        <Select.Item
                          className="font-diablo"
                          key={i}
                          value={option.value}
                        >
                          {option.label}
                        </Select.Item>
                      );
                    })}
                  </Select.Group>
                </Select.Content>
              </Select>
            }
            label="Sockets"
          />
        );
      }}
    />
  );
}

export { SocketsSelect };
