import type { UseFieldArrayAppend, UseFieldArrayRemove } from "react-hook-form";
import { Controller } from "react-hook-form";
import {
  Combobox,
  ComboboxInput,
  ComboboxContent,
  ComboboxGroup,
  ComboboxList,
  ComboboxTrigger,
  ComboboxItem,
  ComboboxValue,
} from "@/components/combobox";
import type { Option } from "@/types";
import { XIcon } from "@/components/icons/x-icon";
import type { Values } from "./schema";

interface ComboboxSearchProps {
  options: Option[];
  searchIndex: number;
  disabled?: boolean;
  remove: UseFieldArrayRemove;
  append: UseFieldArrayAppend<Values, "generalSearchComobox">;
}

export function ComboboxSearch({
  options,
  disabled,
  searchIndex,
  remove,
  append,
}: ComboboxSearchProps): JSX.Element {
  return (
    <div className="flex flex-grow justify-between gap-2">
      <Controller
        name={`generalSearchComobox.${searchIndex}.generalSearchComobox`}
        render={({ field }) => {
          return (
            <Combobox
              disabled={disabled}
              options={options}
              value={field.value as string}
            >
              <ComboboxTrigger>
                <ComboboxValue />
              </ComboboxTrigger>
              <ComboboxContent>
                <ComboboxInput />
                <ComboboxList>
                  <ComboboxGroup>
                    {options.map((option) => {
                      return (
                        <ComboboxItem
                          className="flex cursor-pointer items-center justify-between font-[#565656]"
                          key={option.value}
                          onSelect={() => {
                            field.onChange(option.value);
                          }}
                          value={option.value}
                        >
                          <div className="flex w-full items-center justify-between font-[#565656]">
                            <span className=" font-sans">{option.label}</span>

                            <CloseIcon
                              append={append}
                              remove={remove}
                              searchIndex={searchIndex}
                            />
                          </div>
                        </ComboboxItem>
                      );
                    })}
                  </ComboboxGroup>
                </ComboboxList>
              </ComboboxContent>
            </Combobox>
          );
        }}
      />
    </div>
  );
}

function CloseIcon({
  remove,
  append,
  searchIndex,
}: {
  remove: UseFieldArrayRemove;
  append: UseFieldArrayAppend<Values, "generalSearchComobox">;
  searchIndex: number;
}): JSX.Element {
  return (
    <button
      onClick={() => {
        remove(searchIndex);
        append({
          value: "",
          label: "",
        });
      }}
      type="button"
    >
      <XIcon className="h-3 w-3" />
    </button>
  );
}
