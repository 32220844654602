import {
  useFieldArray,
  useFormContext,
  type UseFieldArrayReturn,
} from "react-hook-form";
import type { Values } from "./schema";

export function useComoboxSearchGeneral(): UseFieldArrayReturn<
  Values,
  "generalSearchComobox"
> {
  const { control } = useFormContext<Values>();
  return useFieldArray<Values, "generalSearchComobox">({
    control,
    name: "generalSearchComobox",
  });
}
