import { useEffect } from "react";
import { searchInputHome } from "@/mocks/options";
import { ComboboxSearch } from "./combobox-search";
import { useComoboxSearchGeneral } from "./use-combobox-search.form";

function SearchInput(): JSX.Element {
  return (
    <div className="flex h-[30px] justify-between gap-3">
      <Input />
      <Button />
    </div>
  );
}

function Input(): JSX.Element {
  const { fields, append, remove } = useComoboxSearchGeneral();

  useEffect(() => {
    if (fields.length > 1) {
      for (let i = 1; i < fields.length; i++) {
        remove(i);
      }
    }

    if (fields.length === 0) {
      append({
        value: "",
        label: "",
      });
    }
  }, [fields, append, remove]);

  return (
    <div className="w-full">
      {fields.map((field, index) => {
        return (
          <div key={field.id}>
            <ComboboxSearch
              append={append}
              options={searchInputHome}
              remove={remove}
              searchIndex={index}
            />
          </div>
        );
      })}
    </div>
  );
}

function Button(): JSX.Element {
  return (
    <button
      className="h-[30px] w-[144px]  bg-[image:url('/images/buttons/button-background-red.png')] bg-cover  font-diablo text-xs uppercase"
      type="submit"
    >
      Search
    </button>
  );
}
export { SearchInput };
