"use client";

import { useState, createContext, useContext } from "react";
import { twMerge } from "tailwind-merge";
import { ChevronDown, CheckIcon } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/popover";
import type { Option } from "@/types";

interface ContextType {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  value: string;
  options: Option[];
  disabled?: boolean;
}

const Context = createContext<ContextType>({} as ContextType);

export type ComboboxProps = React.PropsWithChildren<{
  value: string;
  options: Option[];
  disabled?: boolean;
}>;

function Combobox({
  value,
  options,
  disabled,
  children,
}: ComboboxProps): JSX.Element {
  const [open, setOpen] = useState<ContextType["open"]>(false);

  return (
    <Context.Provider value={{ open, setOpen, value, disabled, options }}>
      <Popover onOpenChange={setOpen} open={open}>
        {children}
      </Popover>
    </Context.Provider>
  );
}

export type ComboboxContentProps = React.PropsWithChildren;
function ComboboxContent({ children }: ComboboxContentProps): JSX.Element {
  return (
    <PopoverContent className="p-0">
      <Command>{children}</Command>
    </PopoverContent>
  );
}

export type ComboboxTriggerProps = React.PropsWithChildren;
function ComboboxTrigger({ children }: ComboboxTriggerProps): JSX.Element {
  const { open, disabled } = useContext(Context);
  return (
    <PopoverTrigger asChild disabled={disabled}>
      <button
        aria-expanded={open}
        className="flex h-8 w-full items-center justify-between truncate bg-gray-500 px-2 font-diablo text-gray-50 disabled:opacity-50"
        //role="combobox"
        type="button"
      >
        <span className="flex-1 truncate text-ellipsis text-left">
          {children}
        </span>
        <ChevronDown className="ml-2 h-4 w-4 shrink-0" />
      </button>
    </PopoverTrigger>
  );
}

export type ComboboxListProps = React.PropsWithChildren;
function ComboboxList({ children }: ComboboxListProps): JSX.Element {
  return <CommandList>{children}</CommandList>;
}

export type ComboboxGroupProps = React.PropsWithChildren;
function ComboboxGroup({ children }: ComboboxGroupProps): JSX.Element {
  return <CommandGroup className="p-0">{children}</CommandGroup>;
}

export type ComboboxItemProps = React.PropsWithChildren<{
  value: string;
  className?: string;
  onSelect: (value: string) => void;
}>;

function ComboboxItem({
  children,
  value,
  className,
  onSelect,
  ...rest
}: ComboboxItemProps): JSX.Element {
  const { value: comboboxValue, setOpen } = useContext(Context);

  return (
    <CommandItem
      {...rest}
      className={twMerge(
        "relative px-2 pl-8 font-diablo hover:bg-background-emphasis hover:bg-gray-700",
        className,
      )}
      onSelect={() => {
        setOpen(false);
        onSelect(value);
      }}
    >
      {value === comboboxValue ? (
        <CheckIcon className={twMerge("absolute left-2 h-4 w-4")} />
      ) : null}
      {children}
    </CommandItem>
  );
}

export interface ComboboxValueProps {
  placeholder?: string;
}

function ComboboxValue({
  placeholder = "Select item...",
}: ComboboxValueProps): JSX.Element {
  const { value, options } = useContext(Context);
  const l = options.find((option) => option.value === value);
  return <span>{l?.label || placeholder}</span>;
}

export type CombobocEmptyProps = React.PropsWithChildren;

function ComboboxEmpty({ children }: CombobocEmptyProps): JSX.Element {
  return <CommandEmpty>{children}</CommandEmpty>;
}

export type ComboboxInputProps = React.ComponentProps<typeof CommandInput>;

function ComboboxInput({
  placeholder = "Search items...",
  className,
  ...rest
}: ComboboxInputProps): JSX.Element {
  return (
    <CommandInput
      className={twMerge(
        "h-8 w-full bg-gray-600 px-2 font-diablo text-gray-50 placeholder:text-gray-100 focus:outline-none",
        className,
      )}
      placeholder={placeholder}
      {...rest}
    />
  );
}

export {
  Combobox,
  ComboboxContent,
  ComboboxTrigger,
  ComboboxGroup,
  ComboboxList,
  ComboboxEmpty,
  ComboboxItem,
  ComboboxValue,
  ComboboxInput,
};
