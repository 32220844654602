import { Controller } from "react-hook-form";
import { Field } from "./field";

function formatNumber(value: string): string {
  const numericValue = value.replace(/\D/g, "");
  return value
    ? new Intl.NumberFormat("en-US").format(Number(numericValue))
    : "";
}

function PriceRangeInput(): JSX.Element {
  return (
    <Field
      component={
        <div className="grid grid-cols-2 ">
          <Controller
            name="priceMin"
            render={({ field }) => {
              return (
                <input
                  className="border border-gray-700 bg-gray-600 px-2 text-center font-diablo text-xs text-gray-50 placeholder:text-gray-50 placeholder:opacity-50 focus:outline-none"
                  maxLength={14}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const formattedValue = formatNumber(e.target.value);
                    e.target.value = formattedValue;
                    field.onChange(formattedValue.replace(/,/g, ""));
                  }}
                  placeholder="MIN"
                  value={formatNumber((field.value as string) || "")}
                />
              );
            }}
          />
          <Controller
            name="priceMax"
            render={({ field }) => {
              return (
                <input
                  className="border border-gray-700 bg-gray-600 px-2 text-center font-diablo text-xs text-gray-50 placeholder:text-gray-50 placeholder:opacity-50 focus:outline-none"
                  maxLength={14}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const formattedValue = formatNumber(e.target.value);
                    e.target.value = formattedValue;
                    field.onChange(formattedValue.replace(/,/g, ""));
                  }}
                  placeholder="MAX"
                  value={formatNumber((field.value as string) || "")}
                />
              );
            }}
          />
        </div>
      }
      label="Price"
    />
  );
}

export { PriceRangeInput };
