"use client";

import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import Image from "next/image";
import { Tooltip } from "@/components/tooltip";

export function GreaterAffixCheckbox({
  disabled,
  checked,
  onCheckedChange,
  className,
}: React.InputHTMLAttributes<HTMLInputElement> & {
  checked?: boolean;
  disabled?: boolean;
  onCheckedChange?: (checked: boolean) => void;
}) {
  const [internalChecked, setInternalChecked] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInternalChecked(e.target.checked);
    onCheckedChange?.(e.target.checked);
  };

  useEffect(() => {
    setInternalChecked(Boolean(checked));
  }, [checked]);

  return (
    <Tooltip.Provider>
      <Tooltip>
        <div
          className={twMerge(
            "rounded-md border border-gray-300 transition-all duration-300",
            internalChecked
              ? "rotate-180 opacity-100"
              : "-rotate-180 opacity-40",
            className,
          )}
        >
          <Tooltip.Trigger asChild>
            <button
              aria-checked={internalChecked}
              className="relative block h-full w-full"
              disabled={disabled}
              onClick={() => {
                inputRef.current?.click();
              }}
              role="checkbox"
            >
              <Image
                alt="Greater Affix"
                fill
                src="/assets/card/decorations/greater-affix.webp"
              />
            </button>
          </Tooltip.Trigger>

          <input
            aria-hidden
            className="sr-only"
            disabled={disabled}
            onChange={onChange}
            ref={inputRef}
            type="checkbox"
          />
        </div>
        <Tooltip.Content className="mt-4">
          Check the box if the affix is a greater affix
        </Tooltip.Content>
      </Tooltip>
    </Tooltip.Provider>
  );
}
