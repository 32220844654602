import { useState, type Dispatch, type SetStateAction } from "react";
import { twMerge } from "tailwind-merge";
import {
  useFormContext,
  useWatch,
  type UseFieldArrayAppend,
} from "react-hook-form";
import { ChevronDownIcon } from "lucide-react";
import { Command as Cmd } from "cmdk";
import { Popover, PopoverTrigger, PopoverContent } from "@/components/popover";
import {
  Command,
  CommandGroup,
  CommandEmpty,
  CommandItem,
  CommandList,
} from "@/components/command";
import { ScrollArea } from "@/components/scroll-area";
import type { Values } from "../../../../schema";

interface Option {
  label: string;
  value: string;
}

export function AddAffixCombobox({
  appendAffix,
  statIndex,
  options,
}: {
  appendAffix: UseFieldArrayAppend<Values, `statFilters.${number}.affixes`>;
  statIndex: number;
  options: Option[];
}): JSX.Element {
  const { control } = useFormContext<Values>();
  const [open, setOpen] = useState<boolean>(false);

  const affixes = useWatch({
    control,
    name: `statFilters.${statIndex}.affixes`,
  });

  const opts = options.filter((option) => {
    return !affixes.some((affix) => affix.key === option.value);
  });

  return (
    <Popover onOpenChange={setOpen} open={open}>
      <Command className="rounded-none">
        <Input isOpen={open} setOpen={setOpen} />
        <PopoverContent
          align="start"
          className="w-[var(--radix-popover-trigger-width)] p-0"
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
        >
          <CommandList>
            <CommandEmpty className="text-center font-diablo">
              No items to show
            </CommandEmpty>
            <Options
              appendAffix={appendAffix}
              options={opts}
              setOpen={setOpen}
            />
          </CommandList>
        </PopoverContent>
      </Command>
    </Popover>
  );
}

function Input({
  setOpen,
  isOpen,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}): JSX.Element {
  return (
    <div className="h-8">
      <div className="relative h-8 bg-gray-700">
        <PopoverTrigger asChild>
          <Cmd.Input asChild>
            <div className="relative h-8">
              <input
                className={twMerge(
                  "bg-white absolute h-8 w-full bg-[transparent] bg-gray-500 text-center font-diablo placeholder:text-gray-50 placeholder:opacity-100 focus:placeholder:opacity-35 focus-visible:outline-none",
                )}
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(true);
                }}
                placeholder="+ ADD STAT"
                type="text"
              />
              <ChevronDownIcon
                className={`absolute right-2 top-1.5 z-10 h-5 w-5 cursor-pointer text-gray-50 transition-transform duration-200 ${
                  isOpen ? "rotate-180" : "rotate-0"
                }`}
              />
            </div>
          </Cmd.Input>
        </PopoverTrigger>
      </div>
    </div>
  );
}

function Options({
  appendAffix,
  setOpen,
  options,
}: {
  appendAffix: UseFieldArrayAppend<Values, `statFilters.${number}.affixes`>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  options: Option[];
}): JSX.Element {
  return (
    <ScrollArea className="top-0 h-[max-content] max-w-full flex-1 overflow-hidden">
      <CommandGroup className="p-0">
        {options.map((option) => (
          <CommandItem
            asChild
            className="my-none w-full cursor-pointer bg-gray-500 px-2 font-sans hover:bg-background-emphasis hover:bg-gray-700"
            key={option.value}
            onSelect={() => {
              appendAffix({
                key: option.value,
                title: option.label,
                min: "",
                max: "",
                enabled: true,
                greaterAffixEnabled: false,
              });
              setOpen(false);
            }}
          >
            <div className="flex cursor-pointer gap-2 font-diablo">
              <span>{option.label}</span>
            </div>
          </CommandItem>
        ))}
      </CommandGroup>
    </ScrollArea>
  );
}
