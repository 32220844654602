import { Controller, useFormContext } from "react-hook-form";
import { Select } from "@repo/ui/select";

interface Option {
  value: string;
  label: string;
}

function Select3({ options }: { options: Option[] }): React.ReactNode {
  const { setValue } = useFormContext();

  return (
    <Controller
      name="select3"
      render={({ field }) => {
        return (
          <Select
            onValueChange={(value) => {
              setValue(field.name, value, { shouldValidate: true });
            }}
            value={field.value as string}
          >
            <Select.Trigger className="font-diablo">
              <Select.Value className="truncate px-4" placeholder="Select" />
            </Select.Trigger>
            <Select.Content>
              <Select.Group>
                {options.map((option) => (
                  <Select.Item
                    className="font-diablo"
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </Select.Item>
                ))}
              </Select.Group>
            </Select.Content>
          </Select>
        );
      }}
    />
  );
}

export { Select3 };
