import { useState } from "react";
import { type UseFieldArrayAppend } from "react-hook-form";
import { ChevronDown } from "lucide-react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/collapsible";
import type { Values } from "../../../schema";

export function AddStatFilterCollapsible({
  appendStat,
}: {
  appendStat: UseFieldArrayAppend<Values, "statFilters">;
}): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Collapsible onOpenChange={setOpen} open={open}>
      <CollapsibleTrigger className="flex h-8 w-full items-center justify-between border border-gray-700 bg-gray-800 px-2 font-diablo">
        <span>+ Add Stat Group</span>
        <ChevronDown
          className={`h-5 w-5 transition-transform duration-200 ${
            open ? "rotate-180" : "rotate-0"
          }`}
        />
      </CollapsibleTrigger>
      <CollapsibleContent>
        <button
          className="h-8 w-full border border-gray-700 bg-gray-500 px-2 text-left font-diablo text-sm uppercase"
          onClick={() => {
            appendStat({
              type: "and",
              affixes: [],
            });
            setTimeout(() => {
              setOpen(false);
            }, 0);
          }}
          type="button"
        >
          And
        </button>
        <button
          className="h-8 w-full border border-gray-700 bg-gray-500 px-2 text-left font-diablo text-sm uppercase"
          onClick={() => {
            appendStat({
              type: "or",
              affixes: [],
            });
            setTimeout(() => {
              setOpen(false);
            }, 0);
          }}
          type="button"
        >
          Or
        </button>
        <button
          className="h-8 w-full border border-gray-700 bg-gray-500 px-2 text-left font-diablo text-sm uppercase"
          onClick={() => {
            appendStat({
              type: "not",
              affixes: [],
            });
            setTimeout(() => {
              setOpen(false);
            }, 0);
          }}
          type="button"
        >
          Not
        </button>
      </CollapsibleContent>
    </Collapsible>
  );
}
