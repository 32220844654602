import { Controller, useFormContext } from "react-hook-form";
import {
  GameplayModeSelect as Select,
  GamplayModeOption,
  GamePlayMode,
} from "@repo/ui/gameplay-mode-select";

function GameplayModeSelect({
  options,
}: {
  options: GamplayModeOption[];
}): JSX.Element {
  const { setValue } = useFormContext();

  return (
    <Controller
      name="gameplayMode"
      render={({ field }) => {
        return (
          <Select
            onValueChange={(value) => {
              setValue(field.name, value, { shouldValidate: true });
            }}
            options={options}
            value={field.value as GamePlayMode}
          >
            <Select.Trigger className="font-diablo" />
            <Select.Content>
              {options.map((option) => {
                return (
                  <Select.Item
                    key={option.value}
                    className="font-diablo"
                    {...option}
                  >
                    {option.label}
                  </Select.Item>
                );
              })}
            </Select.Content>
          </Select>
        );
      }}
    />
  );
}

export { GameplayModeSelect };
