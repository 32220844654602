import {
  FormProvider,
  useFormContext,
  type UseFormReturn,
  type SubmitHandler,
} from "react-hook-form";
import { type Values } from "./schema";
import { Select1 } from "./components/select-1";
import { GameplayModeSelect } from "./components/gameplay-mode-select";
import { Select3 } from "./components/select-3";
import { SearchInput } from "./components/search-input";
import { Collapsible } from "./components/collapsible";
import { GeneralFilters } from "./components/general-filters";
import { StatFilters } from "./components/stat-filters";
import { twMerge } from "tailwind-merge";

export * from "./use-search-form";

export function SearchForm({
  handleSubmit,
  onSubmit,
  children,
  className,
  ...rest
}: UseFormReturn<Values> & {
  onSubmit: SubmitHandler<Values>;
  children: React.ReactNode;
  className?: string;
}): React.ReactNode {
  return (
    <FormProvider {...rest} handleSubmit={handleSubmit}>
      <form
        className={twMerge("grid", className)}
        onSubmit={(e) => {
          void handleSubmit(onSubmit)(e);
        }}
      >
        {children}
      </form>
    </FormProvider>
  );
}

SearchForm.Header = Header;
SearchForm.HeaderLeft = HeaderLeft;
SearchForm.HeaderRight = HeaderRight;
SearchForm.FiltersContainer = FiltersContainer;
SearchForm.ClearFilters = ClearFilters;
SearchForm.ToggleFiltersBtn = ToggleFiltersBtn;

SearchForm.GeneralFilters = GeneralFilters;
SearchForm.StatFilters = StatFilters;
SearchForm.Collapsible = Collapsible;

SearchForm.Select1 = Select1;
SearchForm.GameplayModeSelect = GameplayModeSelect;
SearchForm.Select3 = Select3;
SearchForm.SearchInput = SearchInput;

function Header({ children }: React.PropsWithChildren): JSX.Element {
  return <div className="mb-2 grid gap-2 sm:grid-cols-2">{children}</div>;
}

function HeaderLeft({ children }: React.PropsWithChildren): JSX.Element {
  return <div className="flex items-center gap-2">{children}</div>;
}

function HeaderRight({ children }: React.PropsWithChildren): JSX.Element {
  return (
    <div className="flex items-center gap-2 sm:justify-end">{children}</div>
  );
}

function FiltersContainer({ children }: React.PropsWithChildren): JSX.Element {
  return <div className="mt-2 grid xl:grid-cols-2">{children}</div>;
}

function ClearFilters(): JSX.Element {
  const { reset } = useFormContext<Values>();
  return (
    <button
      className="flex h-[23px] w-[105px] items-center bg-background-muted bg-[image:url('/images/buttons/button-gray-2/button-gray-2-bg.png')] bg-cover px-2 font-diablo text-[10px] uppercase"
      onClick={() => {
        reset();
      }}
      type="button"
    >
      <span>Clear filter</span>
      <span className="ml-1 text-[#B51A1A]" role="img">
        x
      </span>
    </button>
  );
}

function ToggleFiltersBtn({
  children,
  ...rest
}: React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
  return (
    <button
      {...rest}
      className="h-[23px] w-[105px] bg-[image:url('/images/buttons/button-blue/button-blue-bg.png')] bg-cover px-3 font-diablo text-[10px] uppercase"
      type="button"
    >
      {children}
    </button>
  );
}
